<template>
  <v-container
    id="reporte-macro"
    fluid
    tag="section"
    class="pa-1"
  >
    <main-header />
    <v-row>
      <v-col cols="12">
        <v-card tile>
          <v-card-text class="py-6">
            <v-row justify="center" class="pb-5">
              <v-col cols="12">
              <v-list-item class="pl-0">
                <v-list-item-icon class="mr-2">
                  <v-icon color="icono" size="26">mdi-file-chart-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text font-weight-bold text-h4">
                    Obtener Reporte
                  </v-list-item-title>
                  <v-list-item-subtitle class="blue-grey--text">
                    Seleccione el rango de fecha para descargar los datos correspondiente.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
                <!-- <span class="primary--text font-weight-bold text-h4 d-block">Obtener Resumen</span>
                <span class="blue-grey--text text-h5">Seleccione el rango de fecha para descargar los datos correspondiente.</span> -->
              </v-col>
            </v-row>
            <validation-observer ref="REPORT_RANGO" tag="div">
              <v-row justify="center">
                <v-col cols="11" sm="8" md="5" class="mb-0">
                  <validation-provider
                    name="Tipo de Reporte"
                    vid="report"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label-form text="Tipo de Reporte" required />
                    <v-select
                      v-model="report"
                      :items="dataReports"
                      dense
                      outlined
                      clearable
                      :error-messages="errors[0]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :loading="loadReport"
                      :disabled="loadReport"
                      @change="tipoReporte"
                    >
                      <template v-slot:item="{item}">
                        <v-icon left size="20" color="icono">mdi-file-download-outline</v-icon>
                        <span class="text-uppercase">{{ item }}</span>
                      </template>
                      <template v-slot:selection="{item}">
                        <span class="text-uppercase font-weight-medium">{{ item }}</span>
                      </template>
                    </v-select>
                  </validation-provider>
                  <!-- <v-radio-group
                    v-model="typeReport"
                    hide-details
                    row
                    class="mt-0 pt-1"
                    active-class="active-type font-weight-bold"
                  >
                    <v-radio
                      color="primary"
                      :value="0"
                      on-icon="mdi-check-circle-outline"
                    >
                      <template v-slot:label>
                        <v-icon small left>mdi-home-variant-outline</v-icon>
                        <span class="text-h4">Sucursal actual</span>
                      </template>
                    </v-radio>
                    <v-radio
                      color="primary"
                      :value="1"
                      on-icon="mdi-check-circle-outline"
                    >
                      <template v-slot:label>
                        <v-icon small left>mdi-home-city-outline</v-icon>
                        <span class="text-h4">Todas las Sucursales</span>
                      </template>
                    </v-radio>
                  </v-radio-group> -->
                </v-col>
              </v-row>
              <v-row justify="center" v-if="reporteSeleccionado == 'cuentas cobrar'">
                <v-col cols="11" sm="8" md="5">
                  <validation-provider
                    name="Tipo de Cobros"
                    vid="typeCollecT"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label-form text="Tipo de Cobro" required />
                    <v-select
                      v-model="typeCollectSeleted"
                      :items="typeCollect"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      clearable
                      :error-messages="errors[0]"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:item="{item}">
                        <v-icon left size="20" color="icono">mdi-file-download-outline</v-icon>
                        <span class="text-uppercase">{{ item.text }}</span>
                      </template>
                      <template v-slot:selection="{item}">
                        <span class="text-uppercase font-weight-medium">{{ item.text }}</span>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="11" sm="8" md="5">
                  <div class="blue-grey--text text--darken-3 pb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">
                      mdi-calendar-month
                    </v-icon>
                    Fecha Inicio
                    <span class="secondary--text font-weight-bold" v-text="'*'" />
                  </div>
                  <validation-provider
                    name="Fecha Inicio"
                    vid="rango.inicio"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <fecha-input
                      v-model="rango.inicio"
                      :color="errors[0] ? 'red' : 'line-input'"
                      outlined
                      :max="maxDate"
                      :error="errors[0]"
                      :nudge-left="10"
                      width-input="100%"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="11" sm="8" md="5">
                  <div class="blue-grey--text text--darken-3 pb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">
                      mdi-calendar-month
                    </v-icon>
                    Fecha Fin
                    <span class="secondary--text font-weight-bold" v-text="'*'" />
                  </div>
                  <validation-provider
                    name="Fecha Fin"
                    vid="fin"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <fecha-input
                      v-model="rango.fin"
                      :color="errors[0] ? 'red' : 'line-input'"
                      outlined
                      :max="maxDate"
                      :error="errors[0]"
                      :nudge-left="10"
                      width-input="100%"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="11" sm="8" md="5">
                 <v-btn
                   dark
                   depressed
                   color="icono"
                   large
                   block
                   :loading="loading"
                   @click="downloadReport"
                 >
                  <v-icon left>mdi-download</v-icon>
                  descargar reporte</v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment'
import { generateReports, getReports } from '@/services/reportes'
import { get } from 'vuex-pathify'

export default {
  name: 'ReporteMacro',
    components:{
     FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput.vue'
    ),
  },
  data: () => ({
    rango: {
      inicio: '',
      fin: '',
    },
    reporteSeleccionado: '',
    typeReport: 0, // 0: SUCURSAL ACTUAL - 1: TODAS
    maxDate: moment().format("YYYY-MM-DD"),
    loading: false,
    loadReport: false,
    report: '',
    dataReports: [],
    typeCollect: [],
    typeCollectSeleted: null,
  }),
  computed: {
    basic: get('user/infoBasic'),
  },
  created(){
    this.reports()
    this.tipoCobros()
  },
  methods: {
    tipoReporte(valor){
      this.reporteSeleccionado = valor;
      this.typeCollectSeleted = null;
    },
    async downloadReport () {
      const tipoCobro = null;
      const valid = await this.$refs.REPORT_RANGO.validate();
      if (valid) {
        const rangeInvalid = moment(this.rango.inicio).isSameOrAfter(this.rango.fin)

        if (rangeInvalid) {
          this.$refs.REPORT_RANGO.setErrors({
           fin: 'Fecha fin debe ser mayor a la fecha de Inicio.',
          })
          return
        }
        this.loading = true
        try {
          //this.typeCollectSeleted == '3' ? this.typeCollectSeleted = null : this.typeCollectSeleted;
          const report = await generateReports({
            dateIni: this.rango.inicio,
            dateEnd: this.rango.fin,
            report: this.report,
            status: this.typeCollectSeleted,
          })
          const NAME_REPORT = `${this.report.toUpperCase().replaceAll(' ', '_')}_${moment().format('HHmmssSSS')}`
          // const NAME_REPORT = {
          //   0: `REPORTE_MACRO_SUCURSAL_${this.basic.sucursal}_${moment().format('HHmmssSSS')}`,
          //   1: `REPORTE_MACRO_GERENCIAL_${moment().format('HHmmssSSS')}`,
          // }
          var reportURL = window.URL.createObjectURL(new Blob([report]));
          var reportLink = document.createElement('a');

          reportLink.href = reportURL;
          reportLink.setAttribute('download',`${NAME_REPORT}.xlsx`);
          document.body.appendChild(reportLink);
          reportLink.click();
          reportLink.remove();
           this.$root.$showAlert(
            'Operación Exitosa. Espera la descargar.',
            'success',
          )
        } catch (error) {
          this.$root.$showAlert(
            'Lo siento, hubo un error al intentar obtener El Reporte.',
            'error',
          )
        } finally {
          this.loading = false
        }

      }
    },
    async reports(){
      this.loadReport = true
      try {
        this.dataReports = await getReports()
        // console.log(this.dataReports)
      } catch (error) {
        this.$root.$showAlert(
            'Lo siento, hubo un error al intentar obtener el listado de reportes.',
            'error',
          )
      } finally {
        this.loadReport = false
      }
    },
    tipoCobros(){
      this.typeCollect = [
        {text: 'NO PROCESADOS', value: '0'},
        {text: 'PROCESADOS', value: '1'},
        {text: 'ANULADOS', value: '2'},
        {text: 'TODOS', value: '3'},
      ]
    }
  }
}
</script>
<style lang="sass" scope>
.active-type .v-label, .active-type i
  color: #2cc09c !important
  font-weight: 500 !important
</style>
