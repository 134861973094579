<template>
  <v-container
    id="cliente-detalles"
    tag="section"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="12" class="pa-0 light d-flex flex-row justify-space-between align-center pa-3">
        <div>
          <span class="text-h4 font-weight-bold mr-1">
            <v-icon size="20" class="mt-n1">mdi-account-tie</v-icon>
            Cliente
          </span>
          <span class="display-1 primary--text space-normal">
            {{cliente.cli_des}} <strong>({{cliente.co_cli}})</strong>
          </span>
        </div>
        <v-btn depressed plain class="py-1" :to="{name: 'Clientes'}">
          <v-icon size="20" left>mdi-arrow-left-bold-box</v-icon>
          Regresar
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-tabs
          v-model="tabsClient"
          background-color="blue-grey"
          show-arrows
          dark
          slider-color="blue-grey lighten-4"
          active-class="active-tab-details"
          slider-size="3"
          class="fill-height"
          @change="getTabs"
        >
          <v-tab v-for="(tab,i) in tabs" :key="'details-'+i">
            <v-icon size="20" left v-text="tab.icon"></v-icon>
            <span v-text="tab.name"></span>
          </v-tab>
          <v-tab :class="{'d-none': this.tabsClient != 3}">Detalles #{{cli_ped.Numero}}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0">
        <v-card tile flat>
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tabsClient" touchless>
              <v-tab-item>
                <v-row no-gutters>
                  <tab-details :info="cliente" :visita="cli_visita" :portafolio="portafolio" @updateSellers="getCliente" @change="updateHorario"/>
                  <tab-details-map
                    :lat="position.lat"
                    :lng="position.lng"
                    :idCliente="cliente.co_cli"
                    @updateLocation="updateInfo"
                    v-if="!loadingClient"
                  />
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <pedidos-cliente
                  :loading="loadingPedidos"
                  :headers="headPedidos"
                  :pedidos="dataPedidos"
                  :name="cliente.cli_des"
                  :load="loadView"
                  @viewDetails="getPedidoCliente"
                  @update="getPedidos"
                />
              </v-tab-item>
                <v-tab-item>
                  <data-cxc modo="Vendedor" :loading="loadingCXC" :datacxc="cxcCliente" @update="getCXCli" />
                </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" class=" grey lighten-5 d-flex justify-space-between pb-0">
                    <span class="text-h6 font-weight-bold pa-4">Detalles del Pedido</span>
                    <div class="px-4">

                      <v-btn
                        small
                        text
                        class="my-3 px-1"
                        @click="tabsClient = 1"
                      >
                        <v-icon size="20" class="mr-1">mdi-arrow-left</v-icon>
                        Volver
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                 <details-pedido :cliente="cli_ped" :articulos="art_ped" :nombre="cliente.cli_des" />
               </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'

export default {
  name:'cliente-detalles',
  components: {
    TabDetails: () => import(
      /* webpackChunkName: "data-clients" */
      './Components/TabDetails.vue'
    ),
    TabDetailsMap: () => import(
      /* webpackChunkName: "data-clients" */
      './Components/TabDetailsMap.vue'
    ),
    PedidosCliente: () => import(
      /* webpackChunkName: "data-clients" */
      './Components/PedidosCliente.vue'
    ),
    DetailsPedido: () => import(
      /* webpackChunkName: "data-clients" */
      '@/views/Vendedores/Components/DetailsPedido.vue'
    ),
    DataCxc: () => import(
      /* webpackChunkName: "data-clients" */
      '@/views/Vendedores/Components/DataCXC.vue'
    ),
   },
  data: () => ({
    cliente: {},
    dataPedidos: [],
    cli_ped: {},
    art_ped: [],
    cli_visita: [],
    cxcCliente: [],
    portafolio: false,
    loadingClient: false,
    loadingPedidos: false,
    loadingCXC: false,
    loadView: false,
    tabsClient: null,
    headPedidos: [
      { text: 'Nro.', value: 'Numero', class: 'header-data', width: '100' },
      { text: 'Vendedor', value: 'ven_des', class: 'header-data'},
      { text: 'Fecha Pedido', value: 'Fecha', class: 'header-data'},
      { text: 'Observaciones', value: 'Observaciones', class: 'header-data' },
      { text: 'Precio Venta', value: 'TipoPrecio', class: 'header-data' },
      { text: 'Procesado', value: 'Procesado', class: 'header-data', align: 'center' },
      { text: '', value: 'actions', class: 'header-data', width: '80' },
    ],
    tabs:[
      {
        name: 'detalles',
        icon: 'mdi-text-box-check-outline',
        index: 0
      },
      {
        name: 'pedidos',
        icon: 'mdi-cart-arrow-down',
        index: 1
      },
      {
        name: 'cobranzas',
        icon: 'mdi-file-alert-outline',
        index: 2
      },
    ]
  }),
  created(){
    this.getCliente();
    if(this.$route.query.tab){
      const current_tab = this.tabs.filter(tab => tab.name === this.$route.query.tab);
      this.tabsClient = current_tab[0].index;
      if(current_tab[0].index === 1){
        this.getPedidos();
      }
      if(current_tab[0].index === 2){
        this.getCXCli();
      }
    }
  },
  methods:{
    async updateInfo(){
      const { data } = await this.$api.get(`clientes/${this.co_cliente}/show`);
      this.cliente.lat = data.data.lat;
      this.cliente.lng = data.data.lng;
    },
    async getCliente(){
      try {
        //this.overlay = this.loadingClient = true;
        const { data } = await this.$api.get(`clientes/${this.co_cliente}/show`);
        const visita_cli = await this.$api.get(`clientes/${this.co_cliente}/v2/visitas`);
        this.portafolio = visita_cli.data.portafolio;
        this.cliente = data.data;
        if(visita_cli.data.data.length > 0){
          this.cli_visita = visita_cli.data.data;
          this.portafolio = visita_cli.data.portafolio;
        }else{
          const { data } = await this.$api.get(`clientes/${this.co_cliente}/show`);
          this.cli_visita = [{co_cli: this.co_cliente, co_ven: data.data.co_ven}]
        }
      }
      catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Cliente.',
          'error'
        )
      };
      setTimeout(() => {
        this.overlay = this.loadingClient = false;
      }, 2000);
    },
    async getPedidos(){
      this.dataPedidos = [];
      try {
        this.loadingPedidos = true;
        const { data } = await this.$api.get(`clientes/${this.co_cliente}/pedidos`);

        this.dataPedidos = data.data;
      }
      catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los pedidos del Cliente actual.',
          'error'
        )
      }
      this.loadingPedidos = false;
    },
    async getCXCli(){
      this.cxcCliente = {};
      try {
        this.loadingCXC = true;
        const cxc_cli = await this.$api.get(`clientes/${this.co_cliente}/cxc`);
        this.cxcCliente = cxc_cli.data;
      }
      catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las Cuentas por Cobrar del Cliente actual.',
          'error'
        )
      }
      this.loadingCXC = false;
    },
    async getPedidoCliente(item){
      this.loadView = true;
      try {
        const { data } = await this.$api.get(`clientes/${item.Numero}/pedidos/detalles?fecha=${moment(item.Fecha).format("YYYY-MM-DD")}`);

        this.art_ped = data.data;
        this.cli_ped = item;
        this.tabsClient = 3;
      }
      catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Articulos del pedido.',
          'error'
        )
      }
      this.loadView = false;
    },
    getTabs(item){
      this.$router.replace({query: {tab: this.tabs[item].name}});
      if(item === 1){
        this.getPedidos();
      }
      if(item === 2){
        this.getCXCli();
      }
    },
    async updateHorario(){
      try {
        const visita_cli = await this.$api.get(`clientes/${this.co_cliente}/visitas`);
        this.getCliente();
        if(visita_cli.data.data.length > 0)
          this.cli_visita = visita_cli.data.data;

      } catch (error) {
        return;
      }
    }
  },
  computed:{
    co_cliente: get('route/params@cod'),
    overlay: sync('app/overlay'),
    position(){
      const lat = this.cliente.lat ? parseFloat(this.cliente.lat.trim()) : null;
      const lng = this.cliente.lng ? parseFloat(this.cliente.lng.trim()) : null;

      return {
        lat: lat,
        lng: lng
      }
    }
  }

}
</script>
<style lang="sass">
.active-tab-details
  background-color: rgba(255, 255, 255, .12) !important
</style>
